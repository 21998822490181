import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import {
  getModules,
  getCourses,
  getSources,
  setSelectedCourseId,
  setSelectedModuleId,
  setSelectedSourcesIds,
  setSelectedYearsIds,
  getWhiteExamAPI,
  createSession,
  setRestartWrong,
} from "features/medecine";

import {
  resetCourses,
  getNote,
  resetSources,
  resetModules,
} from "features/medecine";
import { addSubscription } from "features/subscription";
import {
  showAnsweredAllQuestionsSwal,
  showAnsweredSomeQuestionsSwal,
  subscriptionActivated,
} from "./utils";
import Confetti from "react-confetti";
import SourceYearSelector from "./SourceYearSelector";
import Select, { components } from "react-select";

const CustomOption = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={data.image}
          alt={data.label}
          style={{ width: 20, height: 20, marginRight: 10 }}
        />
        <span>{data.label}</span>
      </div>
    </components.Option>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.medecine);
  const { isDarkMode } = useSelector((state) => state.home);

  const buttonRef = useRef(null);
  const [message, setMessage] = useState(
    "Veuillez fournir les sources, le cours et le module !"
  );
  const notify = () => toast.error(message);

  const [selectedTypes, setSelectedTypes] = useState({
    QCM: true,
    CC: true,
  });
  const [activeTab, setActiveTab] = useState("tab_revision");
  const [showConfetti, setShowConfetti] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [currentModules, setCurrentModules] = useState(null);
  const [currentCourses, setCurrentCourses] = useState(null);
  const [currentSources, setCurrentSources] = useState([]);
  const [subActivated, setSubActivated] = useState(false);
  const [questionsReady, setQuestionsReady] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState([]);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCurrentSources, setSelectedCurrentSources] = useState(null);

  const [questionNumbers, setQuestionNumbers] = useState("");
  const [duration, setDuration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleQuestionNumbersChange = (e) => {
    const value = e.target.value;
    if (value > 100) {
      setErrorMessage("Le nombre de questions ne peut pas dépasser 100.");
    } else {
      setErrorMessage("");
    }
    setQuestionNumbers(value);
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    if (value > 180) {
      setErrorMessage("La durée ne peut pas dépasser 180 minutes.");
    } else {
      setErrorMessage("");
    }
    setDuration(value);
  };

  const CustomInput = ({ value, onChange, max, placeholder }) => {
    return (
      <input
        type="number"
        value={value}
        onChange={onChange}
        className="custom-input-styled"
        min={10}
        max={max}
        placeholder={placeholder}
      />
    );
  };

  const { subscription, error: subscriptionError } = useSelector(
    (state) => state.subscription
  );
  const {
    modules,
    courses,
    sources,
    questions,
    error: medicineError,
  } = useSelector((state) => state.medecine);

  const specialityOptions = [
    { value: "medecine", label: "Medecine" },
    { value: "chirurgie", label: "Chirurgie" },
    { value: "biologie", label: "Biologie" },
  ];
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleCommencerClick = () => {
    const activeForm = document.querySelector(`#${activeTab} form`);
    if (activeForm) {
      activeForm.requestSubmit();
    }
  };
  useEffect(() => {
    const modulesOptions = modules
      ? modules.map((module) => ({
          value: module.id,
          label: module.title,
          image: `assets/media/modules/${module.title}.svg`,
        }))
      : [];

    const coursesOptions = courses
      ? courses.map((course) => ({
          value: course.id,
          label: course.title,
          has_residanat_source: course.has_residanat_source, // Add this line to include the field
        }))
      : [];

    const SourcesOptions = sources?.map((source) => ({
      value: source.id,
      label: (
        <div>
          {source.title}
          <span className="badge badge-primary mx-2">
            {" "}
            ({source.answered_questions}/
            {source.num_questions + source.num_clinical} Q)
          </span>
        </div>
      ),
      source: source,
    }));

    setCurrentModules(modulesOptions);
    setCurrentCourses(coursesOptions);
    setCurrentSources(SourcesOptions);
  }, [modules, courses, sources]);

  useEffect(() => {
    if (
      !subscription ||
      subscription?.activation_code_fields?.plan === "EXTERNAT"
    ) {
      dispatch(getModules());
    }
  }, [subscription]);

  const handleSpecialityChange = (speciality) => {
    setSelectedSpeciality(speciality);
    dispatch(getModules(speciality.value));
    dispatch(resetCourses());
    dispatch(resetSources());
    setSelectedModule(null);
    setSelectedCourse(null);
    setSelectedSources([]);
    setSelectedCurrentSources(null);
  };

  const handleModuleChange = (module) => {
    const selectedTypesArray = Object.keys(selectedTypes).filter(
      (key) => selectedTypes[key]
    );
    dispatch(
      getCourses({
        module_id: module.value,
        question_types: selectedTypesArray,
      })
    );
    setSelectedModule(module);
    dispatch(resetSources());
    setSelectedCourse(null);
    setSelectedSources([]);
    setSelectedCurrentSources(null);
  };

  const handleCourseChange = (course) => {
    setSelectedCourse(null);
    setSelectedSources([]);
    setSelectedCurrentSources(null);
    // Generate an array of selected types
    const selectedTypesArray = Object.keys(selectedTypes).filter(
      (key) => selectedTypes[key]
    );

    dispatch(
      getSources({
        course_id: course.value,
        question_types: selectedTypesArray,
      })
    );
    setSelectedCourse(course);
    setSelectedSources([]);
  };

  const handleActivation = async () => {
    try {
      await dispatch(addSubscription({ activation_code: activationCode }));
      setSubActivated(true);
    } catch (error) {
      console.error("Error adding subscription:", error);
      // Handle error if necessary
    }
  };
  useEffect(() => {
    dispatch(resetModules());
    if (localStorage.getItem("subActivated") === "true") {
      localStorage.removeItem("subActivated");
      subscriptionActivated(setShowConfetti);
    }
  }, []);

  if (subActivated) {
    setSubActivated(false);
    localStorage.setItem("subActivated", "true");
    setTimeout(() => {
      navigate(0);
    }, 100);
  }

  const onSubmitRevision = (e) => {
    e.preventDefault();
    if (!selectedCourse || !selectedModule || selectedSources.length === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir les sources, le cours et le module");
        toastButton.click();
      }
      return;
    }
    const isAtLeastOneSelected = Object.values(selectedTypes).some(
      (value) => value
    );

    if (!isAtLeastOneSelected) {
      const toastButton = buttonRef.current;
      setMessage("Veuillez sélectionner au moins un type de question.");
      toastButton.click();
      return;
    }
    setCurrentModules([]);
    setCurrentCourses([]);
    setCurrentSources([]);
    dispatch(setSelectedCourseId(selectedCourse.value));
    dispatch(setSelectedModuleId(selectedModule.value));
    dispatch(setSelectedSourcesIds(selectedSources));
    dispatch(setSelectedYearsIds(selectedYears));
    dispatch(getNote(selectedCourse.value));

    const selectedQuestionTypes = Object.keys(selectedTypes).filter(
      (type) => selectedTypes[type]
    );

    dispatch(
      createSession({
        sources: selectedSources,
        course_id: selectedCourse.value,
        module_id: selectedModule.value,
        years: selectedYears,
        question_type: selectedQuestionTypes,
      })
    )
      .unwrap()
      .then(() => {
        setQuestionsReady(true);
      });
  };

  const onSubmitExam = (e) => {
    e.preventDefault();

    if (selectedModule.length === 0) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Veuillez fournir le module !");
        toastButton.click();
      }
      return;
    }

    if (questionNumbers > 100) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("Le nombre de questions ne peut pas dépasser 100.");
        toastButton.click();
      }
      return;
    }
    if (duration > 100) {
      const toastButton = buttonRef.current;
      if (toastButton) {
        setMessage("La durée ne peut pas dépasser 180 minutes.");
        toastButton.click();
      }
      return;
    }

    dispatch(
      getWhiteExamAPI({
        module_id: selectedModule.value,
        question_numbers: questionNumbers,
        duration: duration,
      })
    )
      .unwrap()
      .then(() => {
        setQuestionsReady(true);
      });
  };

  if (questionsReady && !medicineError) {
    dispatch(setRestartWrong(false));
    navigate("/quiz");
    setQuestionsReady(false);
    return null;
  }

  if (
    !questionsReady &&
    medicineError &&
    medicineError.error === "You have answered all the questions"
  ) {
    showAnsweredAllQuestionsSwal(
      dispatch,
      selectedSources,
      selectedCourse,
      selectedModule,
      selectedYears
    );
  }

  if (
    !questionsReady &&
    medicineError &&
    medicineError.error ===
      "You have answered some questions. Do you want to restart or continue?"
  ) {
    showAnsweredSomeQuestionsSwal(
      dispatch,
      selectedSources,
      selectedCourse,
      selectedModule,
      selectedYears,
      setQuestionsReady
    );
  }

  const handleCheckboxTypeChange = (event, type) => {
    const { checked } = event.target;
    setSelectedCourse(null);
    setSelectedSources([]);
    setSelectedCurrentSources(null);
    // Prevent both checkboxes from being deselected
    if (!checked && Object.values(selectedTypes).filter(Boolean).length === 1) {
      return; // Exit if it's the last selected checkbox
    }

    // Update state
    setSelectedTypes((prev) => {
      const updatedTypes = { ...prev, [type]: checked };

      // Generate an array of selected types
      const selectedTypesArray = Object.keys(updatedTypes).filter(
        (key) => updatedTypes[key]
      );
      const module_id_value = selectedModule?.value;
      if (selectedModule) {
        // Dispatch with the array of selected types
        dispatch(
          getCourses({
            module_id: module_id_value,
            question_types: selectedTypesArray,
          })
        );
      }

      return updatedTypes; // Update state
    });
  };

  const handleExamModuleChange = (module) => {
    setSelectedModule(module);
  };
  const selectTheme = (isDarkMode) =>
    isDarkMode
      ? (theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "#333",
            primary: "#555",
            neutral0: "#111329",
            neutral20: "#555",
            neutral80: "#fff",
          },
        })
      : undefined;

  const ResidanatBadge = () => (
    <span
      className="residanat-badge"
      style={{
        marginLeft: 8,
        backgroundColor: "#ff5e00",
        color: "#fff",
        padding: "1px 4px",
        borderRadius: "12px",
        fontSize: "9px",
      }}
    >
      Résidanat
    </span>
  );
  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonRef}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className="row g-5">
        {showConfetti && <Confetti />}
        {subscription === null && (
          <div className="col-xxl-6">
            <div
              className="card border-0 h-md-100"
              data-bs-theme="light"
              style={{
                background:
                  "linear-gradient(112.14deg, violet 0%, rgba(58, 123, 213, 0.3) 100%)",
              }}
            >
              <div className="card-body">
                <div className="row align-items-center h-100">
                  <div className="col-12 ps-xl-13">
                    <div className="text-white mb-6 pt-6">
                      <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2">
                        Mettez à niveau votre plan
                      </span>
                    </div>
                    <span className="fw-bold fs-6 mb-8 d-block opacity-75">
                      Obtenez votre code d'activation dès maintenant pour
                      débloquer des modules exclusifs!
                    </span>
                    {subscriptionError && subscriptionError.message && (
                      <span className="fw-bold fs-6 mb-8 d-block opacity-75 text-danger">
                        {subscriptionError.message}
                      </span>
                    )}
                    <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                      <button
                        className="btn btn-info flex-shrink-0 me-lg-2"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_upgrade_plan"
                      >
                        Voir Instructions
                      </button>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Avez-vous un code d'activation ?"
                          value={activationCode}
                          onChange={(e) => setActivationCode(e.target.value)}
                        />
                        <button
                          className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger flex-shrink-0"
                          data-clipboard-target="#kt_clipboard_1"
                          onClick={handleActivation}
                        >
                          Activer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-xxl-12">
          <div className="row g-5 g-xl-8 mb-5">
            <div className="col-xl-6">
              <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-3">
                      Session de Révision
                    </span>
                    <span class="text-muted fw-semibold fs-7">
                      Créez Votre Propre Session de Révision
                    </span>
                  </h3>
                </div>
                <form onSubmit={onSubmitRevision}>
                  <div className="card-body">
                    <div className="row mb-5 mx-2">
                      {subscription?.is_active &&
                        subscription.activation_code_fields?.plan ===
                          "RESIDANAT" && (
                          <div className="col-12 mb-3">
                            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span className="required">Épreuve</span>
                            </label>
                            <Select
                              options={specialityOptions}
                              placeholder="Selectionnez une specialité..."
                              className="react-select-styled react-select-solid react-select-lg"
                              onChange={handleSpecialityChange}
                              isSearchable={false}
                              theme={selectTheme(isDarkMode)}
                            />
                          </div>
                        )}
                      <div className="col-12 mb-3">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Module</span>
                        </label>
                        <Select
                          name="module"
                          className="react-select-styled react-select-solid react-select-lg"
                          placeholder="Selectionnez un module..."
                          options={selectedSpeciality ? currentModules : []}
                          onChange={handleModuleChange}
                          components={{ Option: CustomOption }}
                          isLoading={loading}
                          isSearchable={false}
                          value={selectedModule || null}
                          theme={selectTheme(isDarkMode)}
                        />
                      </div>
                      {/* Question Types (Only for Constantine Faculty) */}
                      {subscription?.is_active &&
                        (subscription.activation_code_fields?.faculty ===
                          "Constantine" ||
                          [
                            "Première année",
                            "Quatrième année",
                            "Cinquième année",
                            "Sixième année",
                            "Autre",
                          ].includes(
                            subscription.activation_code_fields?.year
                          )) && (
                          <div className="col-12 mb-3">
                            <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                              <span className="required">
                                Types de questions
                              </span>
                            </label>
                            <div className="d-flex flex-row row-fluid fv-row">
                              {/* QCM Checkbox */}
                              <div className="form-check form-check-custom form-check-primary form-check-solid mx-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="QCM"
                                  checked={selectedTypes.QCM}
                                  onChange={(e) =>
                                    handleCheckboxTypeChange(e, "QCM")
                                  }
                                />
                                <label
                                  className="form-check-label fw-bold text-gray-700 fs-5"
                                  htmlFor="QCM"
                                >
                                  QCMs
                                </label>
                              </div>
                              {/* CC Checkbox */}
                              <div className="form-check form-check-custom form-check-primary form-check-solid mx-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="CC"
                                  checked={selectedTypes.CC}
                                  onChange={(e) =>
                                    handleCheckboxTypeChange(e, "CC")
                                  }
                                />
                                <label
                                  className="form-check-label fw-bold text-gray-700 fs-5"
                                  htmlFor="CC"
                                >
                                  Cas Cliniques (CC)
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="col-12 mb-3">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Cours</span>
                        </label>
                        <Select
                          name="course"
                          placeholder="Selectionnez un cours..."
                          className="react-select-styled react-select-solid react-select-lg"
                          options={currentCourses}
                          onChange={handleCourseChange}
                          isLoading={loading}
                          isSearchable={false}
                          value={selectedCourse || null}
                          noOptionsMessage={() =>
                            loading
                              ? "S'il vous plaît, attendez..."
                              : "Aucun cours disponible"
                          }
                          theme={selectTheme(isDarkMode)}
                          formatOptionLabel={(option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>{option.label}</span>
                              {option.has_residanat_source && (
                                <span
                                  className="residanat-badge"
                                  style={{
                                    marginLeft: 8,
                                    backgroundColor: "#ff5e00",
                                    color: "#fff",
                                    padding: "1px 4px",
                                    borderRadius: "12px",
                                    fontSize: "9px",
                                  }}
                                >
                                  Résidanat
                                </span>
                              )}
                            </div>
                          )}
                        />
                      </div>

                      <SourceYearSelector
                        selectedCourse={selectedCourse}
                        currentSources={currentSources}
                        setSelectedSources={setSelectedSources}
                        setSelectedYears={setSelectedYears}
                        selectedCurrentSources={selectedCurrentSources}
                        setSelectedCurrentSources={setSelectedCurrentSources}
                      />
                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary w-100 fs-7">
                      Commencer
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-3">
                      Examen Blanc
                    </span>
                    <span class="text-muted fw-semibold fs-7">
                      Créez Votre Propre Examen
                    </span>
                  </h3>
                </div>
                <form onSubmit={onSubmitExam}>
                  <div className="card-body">
                    <div className="row mb-5 mx-2">
                      {subscription &&
                        subscription.is_active &&
                        subscription.activation_code_fields.plan ===
                          "RESIDANAT" && (
                          <div className="d-flex flex-column mb-5 fv-row">
                            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span className="required">Épreuve</span>
                            </label>
                            <Select
                              options={specialityOptions}
                              placeholder="Selectionnez une specialité..."
                              className="react-select-styled react-select-solid react-select-lg"
                              onChange={handleSpecialityChange}
                              isSearchable={false}
                              theme={
                                isDarkMode
                                  ? (theme) => ({
                                      ...theme,
                                      borderRadius: 4,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#333",
                                        primary: "#555",
                                        neutral0: "#111329",
                                        neutral20: "#555",
                                        neutral80: "#fff",
                                      },
                                    })
                                  : undefined
                              }
                            />
                          </div>
                        )}

                      <div className="d-flex flex-column mb-5 fv-row">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Module</span>
                        </label>
                        <Select
                          name="module"
                          className="react-select-styled react-select-solid react-select-lg"
                          placeholder="Selectionnez un module..."
                          options={currentModules}
                          onChange={handleExamModuleChange}
                          isLoading={loading}
                          components={{ Option: CustomOption }}
                          isSearchable={false}
                          value={selectedModule ? selectedModule.title : ""}
                          theme={
                            isDarkMode
                              ? (theme) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#333",
                                    primary: "#555",
                                    neutral0: "#111329",
                                    neutral20: "#555",
                                    neutral80: "#fff",
                                  },
                                })
                              : undefined
                          }
                        />
                      </div>

                      <div className="flex-fill">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Nombre de questions</span>
                        </label>
                        <div class="input-group mb-5">
                          <CustomInput
                            value={questionNumbers}
                            onChange={handleQuestionNumbersChange}
                            max={100}
                            placeholder="Entrez le nombre de questions"
                          />
                        </div>
                      </div>

                      <div className="flex-fill">
                        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                          <span className="required">Durée (en minutes)</span>
                        </label>
                        <CustomInput
                          value={duration}
                          onChange={handleDurationChange}
                          max={180}
                          placeholder="Entrez la durée en minutes"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary w-100 fs-7">
                      Commencer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
