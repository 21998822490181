import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { getSessions, getPlaylistModules } from "features/medecine";
import { setPageVisible, setSelectedExamSession } from "features/homeSlice";

import AsideFooter from "components/dashboard/sidebar/AsideFooter";
import Session from "components/dashboard/sidebar/Session";
import Examen from "components/dashboard/sidebar/Examen";
import useCloseSidebarOnMobile from "hooks/useCloseSidebarOnMobile";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { subscription } = useSelector((state) => state.subscription);

  const [selectedSpeciality, setSelectedSpeciality] = useState(null);

  const [uniqueSessionsArray, setUniqueSessionsArray] = useState([]);

  const [selectedExamType, setSelectedExamType] = useState(null);

  const closeSidebarOnMobile = useCloseSidebarOnMobile();

  const dispatchGetSessions = useCallback(() => {
    dispatch(getSessions());
  }, [dispatch]);

  useEffect(() => {
    dispatchGetSessions();
    dispatch(setPageVisible({ page: "dashboard", visible: true }));
  }, [dispatch, dispatchGetSessions]);

  const resetSelections = () => {
    setSelectedSpeciality(null);
    setSelectedExamType(null);
  };
  const handlePlaylistClick = () => {
    resetSelections();
    dispatch(getPlaylistModules());
    dispatch(setPageVisible({ page: "playlists", visible: true }));
  };

  return (
    <div
      id="kt_aside"
      className="aside aside-extended"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="auto"
      data-kt-drawer-direction="start"
    >
      <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-0 mb-10"
          id="kt_aside_logo"
        >
          <Link to="/">
            <img
              alt="Logo"
              src="assets/media/logos/demo8.png"
              className="h-75px"
            />
          </Link>
        </div>
        <div
          className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0"
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-overlay-y mb-5 px-5"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_aside_nav"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-offset="0px"
          >
            <ul className="nav flex-column w-100" id="kt_aside_nav_tabs">
              <li
                id="revision"
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Révision"
                onClick={() => {
                  resetSelections();
                  dispatch(
                    setPageVisible({ page: "dashboard", visible: true })
                  );
                }}
              >
                <button
                  className="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light active"
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_dashboard"
                >
                  <i className="ki-duotone ki-book-open text-info fs-2x">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </button>
              </li>
              <li
                id="examen"
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Examen"
                onClick={() => {
                  resetSelections();
                }}
              >
                <a
                  className="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_examen"
                >
                  <i className="ki-duotone ki-book text-info fs-2x">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </a>
              </li>
              <li
                id="note"
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Mes Notes et playlists"
                onClick={() => {
                  resetSelections();
                  handlePlaylistClick();
                  dispatch(setPageVisible({ page: "note", visible: true }));
                  closeSidebarOnMobile();
                }}
              >
                <a
                  className="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_dashboard"
                >
                  <i className="ki-duotone ki-note-2 text-info fs-2x">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </a>
              </li>
              <li
                id="subscription"
                className="nav-item mb-2"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Mes abonnements"
              >
                <a
                  className="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
                  data-bs-toggle="tab"
                  href="#kt_aside_nav_tab_subscription"
                >
                  <i className="ki-duotone ki-brifecase-timer text-info fs-2x">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <AsideFooter />
      </div>
      <div className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace my-2 px-1" id="kt_aside_wordspace">
          <div className="d-flex h-100 flex-column">
            <div
              className="flex-column-fluid hover-scroll-y"
              data-kt-scroll="true"
              data-kt-scroll-activate="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_aside_wordspace"
              data-kt-scroll-dependencies="#kt_aside_secondary_footer"
              data-kt-scroll-offset="0px"
            >
              <div className="tab-content">
                <Session />
                <Examen
                  setSelectedExamType={setSelectedExamType}
                  selectedExamType={selectedExamType}
                  selectedSpeciality={selectedSpeciality}
                  setUniqueSessionsArray={setUniqueSessionsArray}
                  uniqueSessionsArray={uniqueSessionsArray}
                  setSelectedExamSession={setSelectedExamSession}
                  setSelectedSpeciality={setSelectedSpeciality}
                  resetSelections={resetSelections}
                />
                <div
                  className="tab-pane scroll fade"
                  id="kt_aside_nav_tab_subscription"
                  role="tabpanel"
                >
                  {!subscription && (
                    <div className="mx-5 my-5">
                      <div className="text-center pt-10 mb-20">
                        <h2 className="fs-2 fw-bold mb-7">Mes abonnements</h2>
                        <p className="text-gray-400 fs-4 fw-semibold mb-10">
                          Vous n'avez pas encore d'abonnements.
                          <br />
                          Commencez par ajouter votre abonnement.
                        </p>
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_upgrade_plan"
                        >
                          Commencer
                        </button>
                      </div>
                      <div className="text-center px-4">
                        <img
                          src="assets/media/illustrations/sigma-1/18.png"
                          alt=""
                          className="mw-100 mh-300px"
                        />
                      </div>
                    </div>
                  )}
                  {subscription && subscription.is_active && (
                    <div className="mx-5 my-5">
                      {/* <h3 className="fw-bold text-dark mb-10 mx-0">
                        Mes abonnements
                      </h3> */}
                      <div class="card card-flush pt-3 mb-0">
                        <div class="card-header">
                          <div class="card-title">
                            <h3>Mes abonnements</h3>
                          </div>
                        </div>
                        <div class="card-body pt-0 fs-6">
                          <div class="mb-7">
                            <h5 class="mb-3">Info</h5>
                            <div class="d-flex align-items-center mb-1">
                              <span class="fw-bold text-gray-900 text-hover-primary me-2">
                                {user?.name}
                              </span>
                              <span class="badge badge-light-success">
                                Active
                              </span>
                            </div>
                            <span class="fw-semibold text-gray-500 text-hover-primary">
                              {user?.email}
                            </span>
                          </div>
                          <div class="separator separator-dashed mb-7"></div>
                          <div class="mb-7">
                            <h5 class="mb-3">Détails de l'abonnement</h5>
                            <div class="mb-0">
                              <span class="badge badge-light-info me-2">
                                {subscription.activation_code_fields.plan}
                              </span>
                              <span className="badge badge-warning me-2">
                                {subscription?.activation_code_fields?.year !==
                                "Autre"
                                  ? subscription?.activation_code_fields?.year
                                  : null}
                              </span>

                              <span class="fw-semibold text-gray-700">
                                {subscription?.activation_code_fields?.faculty}
                              </span>
                            </div>
                          </div>
                          <div class="separator separator-dashed mb-7"></div>
                          <div class="mb-10">
                            <h5 class="mb-3">Détails de paiement</h5>
                            <div class="mb-0">
                              <div class="fw-semibold text-gray-700 d-flex align-items-center">
                                Baridimob
                                <img
                                  src="assets/media/logos/epaiement.png"
                                  class="w-35px ms-2"
                                  alt=""
                                />
                              </div>
                              <div class="fw-semibold text-gray-700">
                                Expire le:{" "}
                                {
                                  subscription.activation_code_fields
                                    .expiration_date
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="kt_aside_toggle"
        className="aside-toggle btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-5"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <i className="ki-duotone ki-arrow-left fs-2 rotate-180">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </button>
    </div>
  );
};

export default Sidebar;
