import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Enregistrer les composants de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Last10DaysChart = ({ data }) => {
  // Extraire les données
  const labels = data.map((entry) => entry.date);
  const totalData = data.map((entry) => entry.total_answers);
  const correctData = data.map((entry) => entry.correct_answers);
  const wrongData = data.map((entry) => entry.wrong_answers);

  const getGradient = (ctx, color1, color2) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  };

  // Définir les options du graphique
  const chartData = {
    labels,
    datasets: [
      {
        label: "Total",
        data: totalData,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return getGradient(ctx, "#2196F3", "#64B5F6");
        },
        borderColor: "#2196F3",
        borderWidth: 2,
        borderRadius: 5,
      },
      {
        label: "Correctes",
        data: correctData,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return getGradient(ctx, "#4CAF50", "#81C784");
        },
        borderColor: "#4CAF50",
        borderWidth: 2,
        borderRadius: 5,
      },
      {
        label: "Erronées",
        data: wrongData,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return getGradient(ctx, "#F44336", "#E57373");
        },
        borderColor: "#F44336",
        borderWidth: 2,
        borderRadius: 5,
      },
    ],
  };

  // Options du graphique
  const options = {
    responsive: true,
    animation: {
      duration: 5000,
      easing: "easeInOutQuad",
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Nombre de réponses" },
      },
      x: {
        title: { display: true, text: "Période" },
      },
    },
    plugins: {
      legend: { position: "top" },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default Last10DaysChart;
