import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Enregistrer les composants de Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const AnswersDoughnutChart = ({ statistics }) => {
  // Définir les données
  const chartData = {
    labels: ["Correctes", "Partiellement Correctes", "Faux", "Sautées"],
    datasets: [
      {
        data: [
          statistics.correct_answers,
          statistics.partially_correct_answers,
          statistics.wrong_answers,
          statistics.skipped_answers,
        ],
        backgroundColor: ["#54d179", "#ffc107", "#dc3545", "#b3b3b3"], // Vert, Jaune, Rouge, Noir
        hoverOffset: 4,
      },
    ],
  };

  // Options du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
    },
  };

  return <Doughnut data={chartData} options={options} />;
};

export default AnswersDoughnutChart;
