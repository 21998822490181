import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Chart from "react-apexcharts";
import * as questionUtils from "../utils/questionUtils";
import TimerComponent from "components/utils/TimerComponent";

const Exam = ({ finished, setFinished }) => {
  const { examQuestions, duration } = useSelector((state) => state.medecine);

  const parseDuration = (duration) => {
    if (typeof duration === "number") return duration; // Already a number

    if (typeof duration === "string") {
      const parts = duration.split(":").map(Number);
      if (parts.length === 3) {
        const [hours, minutes, seconds] = parts;
        return hours * 3600 + minutes * 60 + seconds;
      }
    }
    return 300; // Default value if parsing fails
  };

  const initialTime = parseDuration(duration);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [remainingTime, setRemainingTime] = useState(initialTime);

  const [question, setQuestion] = useState(
    examQuestions ? examQuestions[currentQuestionIndex] : {}
  );

  // Update the `question` whenever `currentQuestionIndex` changes
  useEffect(() => {
    setQuestion(examQuestions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (finishedA && !finished) {
      questionUtils.updateExamPropositionLabels(selectedAnswers, question);
    }
  }, [question]);

  const totalQuestions = examQuestions?.length;
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [newSessionStats, setNewSessionStats] = useState({
    fauxCount: 0,
    justeCount: 0,
    partJusteCount: 0,
    partielNegatif: 0,
    partielPositif: 0,
    toutOuRien: 0,
  });
  const [finishedA, setFinishedA] = useState(false);
  const [completed, setCompleted] = useState(false);

  // Now you can set the total seconds to the state variable

  const data = [
    newSessionStats.fauxCount,
    newSessionStats.justeCount,
    newSessionStats.partJusteCount,
  ];
  const labels = ["Faux", "justes", "Part/ justes"];

  const options = {
    series: data,
    labels: labels,
    colors: ["#F44336", "#4CAF50", "#FF9800"],
    chart: {
      type: "donut",
    },
    toolbar: {
      show: false,
    },
  };
  const [timer, setTimer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (!isPlaying) {
      clearInterval(timer); // Pause the timer when isPlaying is false
      return;
    }

    // Start the interval only if isPlaying is true
    const newTimer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(newTimer);
          calculateScore();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    setTimer(newTimer);

    return () => clearInterval(newTimer);
  }, [isPlaying]);

  const handleCheckboxChange = (questionId, propositionId) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const selectedProps = prevSelectedAnswers[questionId] || [];
      const updatedSelection = selectedProps.includes(propositionId)
        ? selectedProps.filter((id) => id !== propositionId)
        : [...selectedProps, propositionId];

      return {
        ...prevSelectedAnswers,
        [questionId]: updatedSelection,
      };
    });
  };
  const handleNext = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateScore = () => {
    setCompleted(true);
    if (timer !== null) {
      clearInterval(timer);
    }
    questionUtils.calculateExamScore(
      selectedAnswers,
      examQuestions,
      setNewSessionStats
    );
    setFinished(true);
  };
  const showAnswers = () => {
    setFinished(false);
    setFinishedA(true);
    setCurrentQuestionIndex(0);
  };

  return (
    <>
      <div className="col-xl-12 relative flex justify-center items-center">
        {!finished ? (
          <>
            {/* Timer Component */}
            <div className="absolute left-1/2 -translate-x-1/2 top-0 w-20 h-20 bg-body border-2 rounded-full flex items-center justify-center z-index-2">
              <TimerComponent
                finished={finished}
                remainingTime={remainingTime}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                completed={completed}
              />
            </div>

            <div className="card card-flush card-stretch mt-12 w-500px">
              <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
                <div className="ribbon-label bg-secondary">
                  {`Question ${currentQuestionIndex + 1}/${totalQuestions}`}
                </div>
                <div className="card-title fs-2 p-2 pt-15">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row">
                      <div
                        className={`question-text ${isPlaying ? "" : "blur"}`}
                        dangerouslySetInnerHTML={{
                          __html: question?.question_text,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {question.propositions.map((proposition, index) => (
                  <div key={proposition.id}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      name={`answer-${proposition.id}`}
                      value="apps"
                      id={`kt_radio_option_${proposition.id}`}
                      onChange={() =>
                        handleCheckboxChange(question.id, proposition.id)
                      }
                      checked={
                        selectedAnswers[question.id]?.includes(
                          proposition.id
                        ) || false
                      }
                    />

                    <label
                      id={`kt_radio_option1_${proposition.id}`}
                      className={`proposition btn btn-outline btn-outline-dashed ${
                        isPlaying ? "" : "blur"
                      } ${
                        selectedAnswers[question.id]?.includes(proposition.id)
                          ? "btn-active-light-primary"
                          : ""
                      } p-1 d-flex align-items-center mb-1`}
                      htmlFor={`kt_radio_option_${proposition.id}`}
                    >
                      <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>

                      <span className="d-block fw-semibold text-start  w-500px break-words">
                        <span className="text-gray-800 fw-semibold fs-6 p-1 ">
                          {/* {String.fromCharCode(65 + index)}.{" "} */}
                          {proposition.proposition_text}
                        </span>
                      </span>
                    </label>
                  </div>
                ))}
              </div>
              <div className="card-footer d-flex flex-end">
                <div className="d-flex flex-row-fluid flex-end">
                  {currentQuestionIndex > 0 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`btn btn-info hover-rotate-start ${
                        isPlaying ? "" : "disabled"
                      }`}
                    >
                      <i className="ki-outline ki-left-square fs-2"></i>Previous
                    </button>
                  )}
                </div>
                <div className="d-flex flex-row-fluid flex-end">
                  {currentQuestionIndex < totalQuestions - 1 ? (
                    <button
                      type="button"
                      onClick={handleNext}
                      className={`btn btn-danger hover-rotate-end ${
                        isPlaying ? "" : "disabled"
                      }`}
                    >
                      <i className="ki-outline ki-right-square fs-2"></i>Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`btn btn-danger hover-scale ${
                        isPlaying ? "" : "disabled"
                      }`}
                      onClick={() => {
                        if (!completed) {
                          calculateScore();
                        } else {
                          setFinished(true);
                        }
                      }}
                    >
                      <i className="ki-outline ki-right-square fs-2"></i>
                      Terminer
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card card-xxl-stretch mb-5">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Vous avez terminer cette session
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Voici vos statistiques
                </span>
              </h3>
            </div>
            <div className="card-body card-scroll  h-450px py-3">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="kt_table_widget_5_tab_1"
                >
                  <div className="d-flex flex-wrap flex-stack py-3 me-3">
                    <div className="d-flex flex-column flex-grow-2">
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        Performance
                      </span>
                      <div className="d-flex flex-wrap py-8">
                        <Chart
                          options={options}
                          series={data}
                          type="donut"
                          width="100%"
                        />
                      </div>
                      <div className="text-center px-4 mt-10">
                        <div className="m-2 hover-scale">
                          <button
                            className="btn btn-primary fw-semibold"
                            onClick={showAnswers}
                          >
                            Voir reponses
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-grow-2">
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        La note
                      </span>

                      <div className="d-flex flex-wrap py-8">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.partielNegatif.toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                            <div className="fs-2 fw-bold"></div>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Partiel negatif
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-success me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.partielPositif.toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Partiel positif
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-chart fs-2x text-warning me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.toutOuRien.toFixed(2)}
                                /20
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Tout ou rien
                          </div>
                        </div>
                      </div>
                      <span className="text-muted mt-1 fw-semibold fs-5">
                        Nombre de reponses
                      </span>

                      <div className="d-flex flex-wrap py-8">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-cross-square fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.fauxCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Repondu faux
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check fs-2x text-success me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.justeCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Repondu justes
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-arrow-up-refraction fs-2x text-warning me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold fs-3 mb-1">
                                {newSessionStats.partJusteCount}
                              </span>
                              <br />
                            </h3>
                          </div>
                          <div className="fw-semibold fs-6 text-gray-400">
                            Part/ justes
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Exam;
