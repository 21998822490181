import { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const TimerComponent = ({
  finished,
  remainingTime,
  isPlaying,
  setIsPlaying,
  completed,
}) => {
  const duration = remainingTime; // Use the remaining time as the duration for the countdown
  // Convert time into hours, minutes, and seconds
  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;

  // Conditional Display based on remainingTime
  const timeDisplay =
    remainingTime < 60 ? (
      <span className="text-sm font-bold slide-down">{seconds}s</span>
    ) : (
      <span className="text-sm font-bold">
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );

  const redThreshold = Math.ceil(duration * 0.1);

  return (
    <div className="flex items-center justify-center">
      {!completed && (
        <button
          onClick={() => setIsPlaying((prev) => !prev)}
          className="btn btn-icon btn-default mx-3"
        >
          {isPlaying ? (
            <i className="fas fa-pause fs-2x text-warning"></i>
          ) : (
            <i className="fas fa-play fs-2x text-success"></i>
          )}
        </button>
      )}
      <CountdownCircleTimer
        isPlaying={!finished && duration > 0 && isPlaying && !completed}
        duration={duration}
        initialRemainingTime={duration}
        colors={["#00A300", "#F7B801", "#A30000", "#A30000"]} 
        colorsTime={[duration, duration / 2, duration / 4, 0]} 
        size={70}
        strokeWidth={6}
      >
        {() => timeDisplay}
      </CountdownCircleTimer>
    </div>
  );
};

export default TimerComponent;
