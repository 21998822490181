import React from "react";
import { useSelector } from "react-redux";
import Last10DaysChart from "./Last10DaysChart";
import AnswersDoughnutChart from "./AnswersDoughnutChart";

const Statistics = () => {
  const { statistics } = useSelector((state) => state.medecine);

  return (
    <>
      {statistics && (
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="row g-5 g-xl-8 mb-5">
            {/* Recent Statistics Card */}
            <div className="col-xl-6">
              <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-3">
                      Répartition des Réponses
                    </span>
                    <div className="text-gray-400 pt-1 fw-semibold fs-6">
                      Questions Répondues{" "}
                      <span className="badge badge-lg badge-danger fw-bold mx-2">
                        {statistics?.total_answers ?? 0}/
                        {statistics?.total_questions ?? 0}
                      </span>
                      <span className="badge badge-lg badge-light-success align-self-center">
                        {statistics?.total_questions
                          ? `${(
                              (statistics.total_answers /
                                statistics.total_questions) *
                              100
                            ).toFixed(0)}%`
                          : "0%"}
                      </span>
                    </div>
                  </h3>
                </div>
                <div className="card-body">
                  <AnswersDoughnutChart statistics={statistics} />
                </div>
              </div>
            </div>
            {/* Recent Orders Card */}
            <div className="col-xl-6">
              <div class="card card-xl-stretch mb-5 mb-xl-8">
                <div class="card-header border-0 pt-5">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bold fs-3 mb-1">
                      Mes réponses
                    </span>
                    <span class="text-gray-400 pt-1 fw-semibold fs-6">
                      Nombre et performance des réponses
                    </span>
                  </h3>
                  <div class="card-toolbar">
                    <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          href="#daily_tab"
                        >
                          Ce Jour
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#weekly_tab"
                        >
                          Cette Semaine
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#monthly_tab"
                        >
                          Ce Mois
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-body">
                  <div class="tab-content" id="tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="daily_tab"
                      role="tabpanel"
                    >
                      <table class="table table-row-dashed align-middle">
                        <tbody>
                          {[
                            { label: "Total", key: "total_answers" },
                            { label: "Correctes", key: "correct_answers" },
                            {
                              label: "Erronées",
                              key: "wrong_answers",
                              isError: true,
                            },
                            {
                              label: "Partiellement justes",
                              key: "partially_correct_answers",
                            },
                            { label: "Sautées", key: "skipped_answers" },
                            {
                              label: "Taux de Succès (%)",
                              key: "success_rate",
                            },
                          ].map(({ label, key, isError }) => {
                            const stat =
                              statistics?.daily_statistics?.[key] || {};
                            const value = stat.value ?? "--";
                            const change = stat.change ?? 0;
                            const isPositive = change >= 0;
                            const isIncreaseError = isError && change > 0;
                            return (
                              <tr key={key} class="hover:bg-gray-100">
                                <td class="fw-bold text-start fs-7">{label}</td>
                                <td class="fw-semibold text-end">
                                  <span class="px-2">{value}</span>
                                  {change !== 0 && (
                                    <span
                                      class={`badge fs-base ${
                                        isIncreaseError
                                          ? "badge-light-danger"
                                          : isPositive
                                          ? "badge-light-success"
                                          : "badge-light-danger"
                                      }`}
                                    >
                                      <i
                                        class={`ki-duotone ${
                                          isIncreaseError
                                            ? "ki-arrow-up text-danger"
                                            : isPositive
                                            ? "ki-arrow-up text-success"
                                            : "ki-arrow-down text-danger"
                                        } fs-6 ms-n1`}
                                      ></i>
                                      {change}%
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane fade" id="weekly_tab" role="tabpanel">
                      <table class="table table-row-dashed align-middle">
                        <tbody>
                          {[
                            { label: "Total", key: "total_answers" },
                            { label: "Correctes", key: "correct_answers" },
                            {
                              label: "Erronées",
                              key: "wrong_answers",
                              isError: true,
                            },
                            {
                              label: "Partiellement justes",
                              key: "partially_correct_answers",
                            },
                            { label: "Sautées", key: "skipped_answers" },
                            {
                              label: "Taux de Succès (%)",
                              key: "success_rate",
                            },
                          ].map(({ label, key, isError }) => {
                            const stat =
                              statistics?.weekly_statistics?.[key] || {};
                            const value = stat.value ?? "--";
                            const change = stat.change ?? 0;
                            const isPositive = change >= 0;
                            const isIncreaseError = isError && change > 0;
                            return (
                              <tr key={key} class="hover:bg-gray-100">
                                <td class="fw-bold text-start fs-7">{label}</td>
                                <td class="fw-semibold text-end">
                                  <span class="px-2">{value}</span>
                                  {change !== 0 && (
                                    <span
                                      class={`badge fs-base ${
                                        isIncreaseError
                                          ? "badge-light-danger"
                                          : isPositive
                                          ? "badge-light-success"
                                          : "badge-light-danger"
                                      }`}
                                    >
                                      <i
                                        class={`ki-duotone ${
                                          isIncreaseError
                                            ? "ki-arrow-up text-danger"
                                            : isPositive
                                            ? "ki-arrow-up text-success"
                                            : "ki-arrow-down text-danger"
                                        } fs-6 ms-n1`}
                                      ></i>
                                      {change}%
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane fade" id="monthly_tab" role="tabpanel">
                      <table class="table table-row-dashed align-middle">
                        <tbody>
                          {[
                            { label: "Total", key: "total_answers" },
                            { label: "Correctes", key: "correct_answers" },
                            {
                              label: "Erronées",
                              key: "wrong_answers",
                              isError: true,
                            },
                            {
                              label: "Partiellement justes",
                              key: "partially_correct_answers",
                            },
                            { label: "Sautées", key: "skipped_answers" },
                            {
                              label: "Taux de Succès (%)",
                              key: "success_rate",
                            },
                          ].map(({ label, key, isError }) => {
                            const stat =
                              statistics?.monthly_statistics?.[key] || {};
                            const value = stat.value ?? "--";
                            const change = stat.change ?? 0;
                            const isPositive = change >= 0;
                            const isIncreaseError = isError && change > 0;
                            return (
                              <tr key={key} class="hover:bg-gray-100">
                                <td class="fw-bold text-start fs-7">{label}</td>
                                <td class="fw-semibold text-end">
                                  <span class="px-2">{value}</span>
                                  {change !== 0 && (
                                    <span
                                      class={`badge fs-base ${
                                        isIncreaseError
                                          ? "badge-light-danger"
                                          : isPositive
                                          ? "badge-light-success"
                                          : "badge-light-danger"
                                      }`}
                                    >
                                      <i
                                        class={`ki-duotone ${
                                          isIncreaseError
                                            ? "ki-arrow-up text-danger"
                                            : isPositive
                                            ? "ki-arrow-up text-success"
                                            : "ki-arrow-down text-danger"
                                        } fs-6 ms-n1`}
                                      ></i>
                                      {change}%
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-2 gx-xl-8">
            <div className="col-xl-6 mb-5 mb-xl-10">
              <div className="card card-flush h-xl-100">
                <div className="card-header pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      🏆 Leaderboard
                    </span>
                    <label className="text-gray-400 pt-1 fw-semibold fs-6">
                      Classement des Meilleurs Performances
                    </label>
                  </h3>
                </div>
                <div className="card-body py-3">
                  <div className="table-responsive">
                    <table className="table table-row-dashed align-middle gy-3 gs-3">
                      <thead>
                        <tr className="fs-7 fw-bold border-0 text-gray-400">
                          <th className="min-w-auto"></th>
                          <th className="min-w-100px">Docteur</th>
                          <th className="text-end min-w-100px">Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistics?.leaderboard?.leaderboard?.map(
                          (entry, index) => (
                            <tr
                              key={index}
                              className={
                                statistics?.leaderboard?.userRanking
                                  ?.user_name === entry.user_name
                                  ? "table-success"
                                  : ""
                              }
                            >
                              <td className="fw-bold text-center">
                                {index === 0
                                  ? "🥇"
                                  : index === 1
                                  ? "🥈"
                                  : index === 2
                                  ? "🥉"
                                  : index + 1}
                              </td>
                              <td className="fw-bold">
                                {entry.user_name || "N/A"}
                              </td>
                              <td className="text-end fw-bold">
                                {entry.score ?? 0}
                              </td>
                            </tr>
                          )
                        )}

                        {statistics?.leaderboard?.userRanking &&
                          !statistics?.leaderboard?.leaderboard?.some(
                            (entry) =>
                              entry.user_name ===
                              statistics?.leaderboard?.userRanking?.user_name
                          ) && (
                            <>
                              <tr className="border-top">
                                <td
                                  colSpan="3"
                                  className="text-center text-gray-500 fw-bold"
                                >
                                  Votre classement
                                </td>
                              </tr>
                              <tr className="table-warning">
                                <td className="fw-bold text-center">
                                  {statistics?.leaderboard?.userRanking?.rank ??
                                    "--"}
                                </td>
                                <td className="fw-bold">
                                  {statistics?.leaderboard?.userRanking
                                    ?.user_name ?? "N/A"}
                                </td>
                                <td className="text-end fw-bold">
                                  {statistics?.leaderboard?.userRanking?.score
                                    ? `${statistics.leaderboard.userRanking.score}`
                                    : "--"}
                                </td>
                              </tr>
                            </>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <p className="text-muted mt-2">
                    <small>
                      +3 points pour correcte. <br /> +1 points pour partielle.{" "}
                      <br /> -2 points pour incorrecte.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 mb-5 mb-xl-10">
              <div className="card card-flush h-xl-100">
                <div className="card-header pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Modules
                    </span>
                    <span className="text-gray-400 pt-1 fw-semibold fs-6">
                      Participation aux modules
                    </span>
                  </h3>
                </div>
                <div className="card-body py-3">
                  <div className="table-responsive">
                    <table className="table table-row-dashed align-middle gy-3 gs-3">
                      <thead>
                        <tr className="fs-7 fw-bold border-0 text-gray-400">
                          <th className="min-w-auto">Module</th>
                          <th className="text-end min-w-100px">
                            Votre <br />
                            Score
                          </th>
                          <th className="text-end min-w-100px">
                            Meilleur Score
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistics?.success_by_module?.length > 0 ? (
                          statistics.success_by_module.map((entry, index) => (
                            <tr
                              key={index}
                              className={
                                entry.user_score > 0 &&
                                entry.user_score === entry.best_score
                                  ? "table-success"
                                  : ""
                              }
                            >
                              <td className="fw-bold text-start">
                                {entry.module ?? "N/A"}
                              </td>

                              <td className="fw-bold text-end">
                                {entry.user_score > 0 &&
                                entry.user_score === entry.best_score
                                  ? "🥇"
                                  : ""}{" "}
                                {entry.user_score ?? 0}
                              </td>

                              <td className="text-end fw-bold">
                                🔴 {entry.best_score ?? 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={3}
                              className="text-center text-gray-500 fw-bold"
                            >
                              Aucun module trouvé.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <p className="text-muted mt-2">
                    <small>
                      +3 points pour correcte. <br /> +1 points pour partielle.{" "}
                      <br /> -2 points pour incorrecte.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-2 gx-xl-8 my-5">
            <div className="card card-flush h-xl-100">
              <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Les 10 derniers jours
                  </span>
                  <span className="text-gray-400 pt-1 fw-semibold fs-6">
                    Participation aux 10 derniers jours
                  </span>
                </h3>
              </div>
              <div className="card-body py-3">
                {statistics?.last_10_days_statistics?.length ? (
                  <Last10DaysChart data={statistics.last_10_days_statistics} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistics;
