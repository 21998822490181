import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteSession, setSelectedCourseId } from "features/medecine";
import { showResumeSessionSwal } from "../utils";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Session = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sessions, questions } = useSelector((state) => state.medecine);
  const [questionsReady, setQuestionsReady] = useState(false);

  if (questionsReady && questions && !questions.error && !questions.message) {
    navigate("/quiz");
    setQuestionsReady(false);
    return null;
  }
  const formatDateAndTime = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} à ${formattedTime}`;
  };

  const showResumeSessionSwalHandler = (session) => {
    const qCourse_id = session.course.id;
    showResumeSessionSwal(dispatch, session, setQuestionsReady, qCourse_id);
    dispatch(setSelectedCourseId(qCourse_id));
  };

  const showSwalDeleteSession = (id) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment supprimer cette session de révision ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSession(id));
      }
    });
  };
  return (
    <div
      className="tab-pane fade active show"
      id="kt_aside_nav_tab_dashboard"
      role="tabpanel"
    >
      <div className="mx-5 my-5">
        <h3 className="fw-bold text-dark mb-10 mx-0">Sessions ouvertes</h3>

        {sessions && sessions.length > 0 ? (
          <div className="mb-12">
            {sessions.map((session, index) => {
              const totalQuestions = session.total_questions;
              const remainingQuestions = session.remaining_questions_count;

              // Calculate the percentage of completed questions
              const answeredQuestions = totalQuestions - remainingQuestions;
              const progressPercentage = (
                (answeredQuestions / totalQuestions) *
                100
              ).toFixed(2);

              return (
                <div
                  key={index}
                  className="card card-flush bg-light-primary border h-md-50 mb-5"
                >
                  <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                      <div className="d-flex flex-row w-100">
                        <div className="d-flex d-flex-column-fluid flex-start">
                          <span className="text-gray-700 fs-8 fst-italic text-start mb-2">
                            <span className="text-gray-900 fs-8 fst-italic text-start mb-2">
                              {" "}
                              Créée le :{" "}
                            </span>{" "}
                            {formatDateAndTime(session.updated_at)}
                          </span>
                        </div>
                        <div className="d-flex d-flex-column-auto flex-center flex-end">
                          <button
                            class="btn btn-icon btn-sm btn-active-color-primary"
                            data-bs-toggle="tooltip"
                            title="Supprimer Session"
                            onClick={() => showSwalDeleteSession(session.id)}
                          >
                            <i class="ki-duotone ki-cross text-danger fs-1">
                              <span class="path1"></span>
                              <span class="path2"></span>
                            </i>
                          </button>
                        </div>
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={`assets/media/modules/${session.module.title}.svg`}
                          alt={session.module.title}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                        <span>{session.module.title}</span>
                      </div>
                      <span className="text-gray-900 pt-1 fw-semibold fs-7">
                        {session.course.title}
                      </span>
                      <div>
                        {session && session.remaining_question_types.map(
                          (questionType, index) => (
                            <span
                              key={index}
                              className="badge badge-danger me-1"
                            >
                              {questionType}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-1 pb-4 d-flex align-items-center">
                    <div className="d-flex align-items-center flex-column mt-1 w-100">
                      <div className="d-flex justify-content-between w-100 mt-auto">
                        <span className="fw-bolder fs-6 text-dark">
                          {remainingQuestions} à l'objectif
                        </span>
                        <span className="fw-bold fs-6 text-dark">
                          {progressPercentage}%
                        </span>
                      </div>
                      <div className="h-8px mx-3 w-100 bg-light-success rounded">
                        <div
                          className="bg-primary rounded h-6px"
                          role="progressbar"
                          style={{ width: `${progressPercentage}%` }}
                          aria-valuenow={progressPercentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-success mt-1"
                        onClick={() => showResumeSessionSwalHandler(session)}
                      >
                        Reprendre
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mx-5">
            <div className="text-center pt-10 mb-20">
              <p className="text-gray-400 fs-4 fw-semibold mb-10">
                Aucune session disponible pour le moment
              </p>
            </div>
            <div className="text-center px-4">
              <img
                src="assets/media/illustrations/sigma-1/18.png"
                alt=""
                className="mw-100 mh-300px"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Session;
