import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { postReport } from "features/medecine";
import toast, { Toaster } from "react-hot-toast";

const SignalModal = ({ currentQuestion }) => {
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.medecine);
  const [reported, setReported] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      return; // Don't submit empty comments
    }

    try {
      dispatch(
        postReport({ question_id: currentQuestion.id, report_text: comment })
      );
      setReported(true);
      handleButtonClick();
      // Handle success or show a success message if needed
    } catch (error) {
      // Handle error or show an error message if needed
    }
  };

  const resetReportParams = () => {
    setReported(false);
    setComment("");
  };

  const buttonRef = useRef(null);
  const notify = () =>
    toast.success("Votre rapport a été envoyé avec succès! Merci.");

  const handleButtonClick = () => {
    const toastButton = buttonRef.current;
    if (toastButton) {
      toastButton.click();
    }
  };
  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonRef}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div
        className="modal fade"
        id="kt_modal_signal"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <form
              className="form"
              onSubmit={handleSubmit}
            >
              <div className="modal-header">
                <h2>Signaler une question</h2>
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>
              <div className="modal-body py-10 px-lg-17">
                <div
                  className="scroll-y me-n7 pe-7"
                  data-kt-scroll-offset="300px"
                >
                  <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                    <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-semibold">
                        <h4 className="text-gray-900 fw-bold">Important</h4>
                        <div className="fs-6 text-gray-700">
                          veuillez nous fournir des détails supplémentaires en
                          utilisant le formulaire de signalement ci-dessous.
                          Votre contribution nous aide à améliorer l'expérience
                          d'apprentissage pour tous. Nous vous remercions pour
                          votre coopération et votre aide à rendre notre
                          plateforme encore meilleure.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column mb-5 fv-row h-200px">
                    <label className="required fs-5 fw-semibold mb-2">
                      Commentaire
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      placeholder=""
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer flex-center">
                {!reported && (
                  <>
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      className="btn btn-light me-3"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {!loading && (
                        <span className="indicator-label">Envoyer</span>
                      )}
                      {loading && (
                        <span className="indicator-progress">
                          Attendez s'il vous plait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </>
                )}
                {reported && (
                  <button
                    type="reset"
                    data-bs-dismiss="modal"
                    className="btn btn-light me-3"
                    onClick={resetReportParams}
                  >
                    Merci pour votre commentaire
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalModal;
