import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleFullscreen } from "components/utils/fullscreen";
import { setIsDarkMode, setPageVisible } from "features/homeSlice";
import UserInfo from "components/general/User_info";
import Notification from "components/general/Notification";

const Navigation = () => {
  const dispatch = useDispatch();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { isDarkMode } = useSelector((state) => state.home);

  const handleThemeChange = () => {
    const newThemeMode = isDarkMode ? "light" : "dark";

    // Toggle the theme mode in the state
    dispatch(setIsDarkMode(!isDarkMode));

    // Apply the theme to the document and save it in localStorage
    document.documentElement.setAttribute("data-bs-theme", newThemeMode);
    localStorage.setItem("data-bs-theme", newThemeMode);
  };

  const handleToggleFullscreen = () => {
    toggleFullscreen();
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  const toggleSidebar = () => {
    setTimeout(() => {
      document.getElementById("kt_aside").classList.add("drawer-on");
    }, 0);
  };

  const resetSelections = () => {
    dispatch(setPageVisible({ page: "dashboard", visible: true }));
  };

  return (
    <>
      <div
        id="kt_header"
        className="header"
      >
        <div
          className="container d-flex align-items-center justify-content-between"
          id="kt_header_container"
        >
          <div
            className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
          >
            <h1 className="text-dark fw-bold my-0 fs-3">Dashboard</h1>
            <ul className="breadcrumb fw-semibold fs-base my-1">
              <li
                className="breadcrumb-item text-muted"
                onClick={resetSelections}
              >
                <span className="text-muted">Accueil</span>
              </li>
            </ul>
          </div>
          <div
            className="d-flex d-lg-none align-items-center ms-n2 me-2"
            onClick={toggleSidebar}
          >
            <div className="btn btn-icon btn-active-icon-primary">
              <i className="ki-duotone ki-abstract-14 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
            <a href="/" className="d-flex align-items-center">
              <img
                alt="Logo"
                src="assets/media/logos/demo9.png"
                className="h-70px"
              />
            </a>
          </div>
          <div
            id="header_nav"
            className="d-flex align-items-center flex-shrink-0"
          >
            <div
              id="full-screen"
              className="d-flex align-items-center ms-3 ms-lg-4"
            >
              <button
                onClick={handleToggleFullscreen}
                className="btn btn-icon btn-outline-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px"
              >
                <i
                  className={`fas ${
                    !isFullscreen ? "fa-maximize" : "fa-minimize"
                  } fs-3 text-dark`}
                ></i>
              </button>
            </div>
            <div className="d-flex align-items-center ms-3 ms-lg-4" id="mode">
              <button
                onClick={handleThemeChange}
                className="btn btn-icon btn-outline-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px"
              >
                <i
                  className={`ki-duotone fs-1 ${
                    isDarkMode ? "ki-sun" : "ki-moon"
                  } text-dark`}
                >
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                </i>
              </button>
            </div>

            <div
              className="d-flex align-items-center ms-3 ms-lg-4"
              id="kt_header_user_menu1_toggle"
            >
              <div
                className="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-primary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px"
                data-kt-menu-trigger="hover"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <i className="ki-duotone ki-user fs-1 text-dark">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                data-kt-menu="true"
              >
                <UserInfo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
