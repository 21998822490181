import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const SourceYearSelector = ({
  selectedCourse,
  currentSources,
  setSelectedSources,
  setSelectedYears,
  selectedCurrentSources,
  setSelectedCurrentSources,
}) => {
  const [yearOptions, setYearOptions] = useState({});
  const { isDarkMode } = useSelector((state) => state.home);

  const [selectedCurrentYears, setSelectedCurrentYears] = useState({});
  const { loading } = useSelector((state) => state.medecine);

  const { courses } = useSelector((state) => state.medecine);

  useEffect(() => {
    const selectedSourceIds = selectedCurrentSources?.map(
      (source) => source.value
    );

    const years = {};
    currentSources?.forEach((source) => {
      if (
        selectedSourceIds?.includes(source.source.id) &&
        source.source.years_univ.length > 0
      ) {
        const yearOptionsWithSelectAll = [
          { value: "select_all", label: "Select All" },
          ...source.source.years_univ.map((year) => ({
            value: year.year,
            label: (
              <>
                {year.year}{" "}
                {year.is_clinical && (
                  <span className="badge badge-danger">CC</span>
                )}
              </>
            ),
          })),
        ];

        years[source.source.id] = yearOptionsWithSelectAll;
      }
    });

    setYearOptions(years);

    const selectedYearsBySource = {};

    // Normalize the data structure for selected years
    Object.entries(selectedCurrentYears).forEach(
      ([sourceId, selectedYears]) => {
        selectedYearsBySource[sourceId] = selectedYears.map((year) =>
          typeof year === "object" ? year.value : year
        );
      }
    );

    setSelectedYears(selectedYearsBySource);

    setSelectedSources(selectedSourceIds);
  }, [selectedCurrentSources, selectedCurrentYears, currentSources]);

  useEffect(() => {
    setSelectedCurrentYears({});
  }, [courses]);

  const handleSelectChange = (selected) => {
    setSelectedCurrentSources(selected || []);
    const selectedSourceIds = selected
      ? selected.map((source) => source.value)
      : [];
    setSelectedCurrentYears((prev) => {
      const updated = { ...prev };
      Object.keys(prev).forEach((sourceId) => {
        if (!selectedSourceIds.includes(sourceId)) {
          delete updated[sourceId];
        }
      });
      return updated;
    });
  };

  // Add "Select All" and "Deselect All" options
  const handleSelectAll = () => {
    setSelectedCurrentSources(currentSources);
  };
  const handleYearChange = (sourceId, selectedYears) => {
    // If 'Select All' is selected, select all years
    if (selectedYears.some((year) => year.value === "select_all")) {
      const allYears = yearOptions[sourceId].filter(
        (year) => year.value !== "select_all"
      );
      setSelectedCurrentYears((prev) => ({
        ...prev,
        [sourceId]: allYears,
      }));
    } else {
      setSelectedCurrentYears((prev) => ({
        ...prev,
        [sourceId]: selectedYears,
      }));
    }
  };

  const selectAllOption = {
    value: "select-all",
    label: "Tout sélectionner",
    source: "all",
  };
  let isArray = false;
  if (currentSources) {
    isArray = Array.isArray(currentSources);
  }

  const optionsWithSelectAll = isArray
    ? [selectAllOption, ...currentSources]
    : [];

  const handleChange = (selected) => {
    if (selected && selected.some((option) => option.value === "select-all")) {
      // If "Select All" is selected, select all options
      handleSelectAll();
    } else {
      // Otherwise, handle the normal selection
      handleSelectChange(selected);
    }
  };
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#f5f5f5" : "#e1e1e1", // Background of selected items
      color: isDarkMode && "#000", // Text color of selected items
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isDarkMode && "#000", // Text color of selected items
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: isDarkMode && "#000", // Remove button color
      ":hover": {
        backgroundColor: isDarkMode && "#e1e1e1", // Remove button hover background
        color: isDarkMode && "#000", // Remove button hover color
      },
    }),
  };

  return (
    <>
      <>
        <div className="col-12 mb-5">
          <label className="fs-5 fw-semibold required mb-2">Sources</label>
          <Select
            className="react-select-styled react-select-solid react-select-lg"
            classNamePrefix="react-select"
            options={optionsWithSelectAll}
            placeholder="Select sources"
            isMulti
            onChange={handleChange}
            value={selectedCurrentSources}
            isLoading={loading}
            isSearchable={false}
            theme={
              isDarkMode
                ? (theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: "#333",
                      primary: "#555",
                      neutral0: "#111329",
                      neutral20: "#555",
                      neutral80: "#fff",
                    },
                  })
                : undefined
            }
            styles={customStyles}
          />
        </div>

        {selectedCurrentSources?.map((source) => {
          const years = yearOptions[source.value];

          return years ? (
            <div key={source.value} className="col-12">
              <label className="form-label">{source.label}</label>
              <Select
                className="react-select-styled react-select-solid react-select-sm"
                classNamePrefix="react-select"
                options={years}
                placeholder="Select year"
                isMulti
                onChange={(selectedYears) =>
                  handleYearChange(source.value, selectedYears)
                }
                value={selectedCurrentYears[source.value] || []}
                isLoading={loading}
                isDisabled={selectedCourse ? false : true}
                isSearchable={false}
                theme={
                  isDarkMode
                    ? (theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: "#333",
                          primary: "#555",
                          neutral0: "#111329",
                          neutral20: "#555",
                          neutral80: "#fff",
                        },
                      })
                    : undefined
                }
                styles={customStyles}
              />
            </div>
          ) : null;
        })}
      </>
    </>
  );
};

export default SourceYearSelector;
