import React from "react";
import Navigation from "components/dashboard/Navigation";
import Main from "components/dashboard/Main";

const Home = () => {
  return (
    <>
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Navigation />
        <Main />
      </div>
    </>
  );
};

export default Home;
