import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Layout from "components/quiz/Layout";
import Question from "components/quiz/Question";
import Exam from "components/quiz/Exam";
import { checkAuth } from "features/auth";
import {
  resetQuestions,
  resetExamQuestions,
  setSelectedCourseId,
  setSelectedModuleId,
  setSelectedSourcesIds,
} from "features/medecine";
import { toggleFullscreen } from "components/utils/fullscreen";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { toggleAssistant, setIsDarkMode } from "features/homeSlice";

const QuizPage = () => {
  const navigate = useNavigate();
  const { examQuestions, questions } = useSelector((state) => state.medecine);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [quizComponent, setQuizComponent] = useState(false);
  const { isDarkMode } = useSelector((state) => state.home);
  const assistant = useSelector((state) => state.home.assistant);
  const [finished, setFinished] = useState(false);

  const handleThemeChange = () => {
    const newThemeMode = isDarkMode ? "light" : "dark";

    dispatch(setIsDarkMode(!isDarkMode));
    document.documentElement.setAttribute("data-bs-theme", newThemeMode);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (questions && questions.length > 0) {
      setQuizComponent("Quiz");
    } else if (examQuestions && examQuestions.length > 0) {
      setQuizComponent("Exam");
    }
    // Dispatch the checkAuth action initially when the component mounts
    dispatch(checkAuth());

    // Create an interval to dispatch the checkAuth action every 5 minutes (300000 milliseconds)
    const interval = setInterval(() => {
      dispatch(checkAuth());
    }, 300000);

    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, questions, examQuestions]);

  const { isAuthenticated, user } = useSelector((state) => state.user);

  const handleQuitClick = () => {
    dispatch(resetQuestions());
    dispatch(setSelectedCourseId(null));
    dispatch(setSelectedModuleId(null));
    dispatch(setSelectedSourcesIds(null));
    dispatch(resetExamQuestions());
    navigate("/dashboard");
  };
  if (!user && !isAuthenticated) {
    navigate("/login");
  }

  const handleToggleFullscreen = () => {
    toggleFullscreen();
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  const showSwalQuitLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment quitter cette session ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Quitter",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleQuitClick();
      }
    });
  };
  const handleAssistantToggle = () => {
    dispatch(toggleAssistant());
  };
  return (
    <Layout title="Elmajor | Quiz" content="Quiz page">
      <div class="card card-xl-stretch">
        <div className="px-9 pt-7 card rounded-0 h-200px w-100 bg-primary d-flex flex-column">
          <div class="d-flex flex-stack">
            <div className="d-flex align-items-center">
              <Link to="/dashboard">
                <img
                  alt="Logo"
                  src="assets/media/logos/demo7.png"
                  className="h-60px h-lg-100px"
                />
              </Link>
            </div>
            <div class="ms-1">
              <div className="d-flex align-items-center">
                {quizComponent === "Quiz" && (
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      checked={assistant}
                      onChange={handleAssistantToggle}
                      id="assistant-toggle"
                    />
                    <span
                      className="form-check-label fw-semibold text-muted"
                      htmlFor="assistant-toggle"
                    >
                      Assistant
                    </span>
                  </label>
                )}

                <div className="d-flex align-items-center ms-3 ms-lg-4">
                  <button
                    onClick={handleToggleFullscreen}
                    className="btn btn-icon btn-outline-dashed btn-outline btn-outline-light w-30px h-30px w-lg-40px h-lg-40px"
                  >
                    <i
                      className={`fas ${
                        !isFullscreen ? "fa-maximize" : "fa-minimize"
                      } fs-3`}
                    ></i>
                  </button>
                </div>
                <div className="d-flex align-items-center ms-3 ms-lg-4">
                  <button
                    onClick={handleThemeChange}
                    className="btn btn-icon  btn-outline btn-outline-light w-30px h-30px w-lg-40px h-lg-40px"
                  >
                    <i
                      className={`ki-duotone fs-1 ${
                        isDarkMode ? "ki-sun" : "ki-moon"
                      }`}
                    >
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                      <span className="path7"></span>
                      <span className="path8"></span>
                      <span className="path9"></span>
                    </i>
                  </button>
                </div>
                <div className="d-flex align-items-center ms-3 ms-lg-4">
                  <button
                    className="btn btn-icon btn-danger w-35px h-35px w-lg-40px h-lg-40px"
                    data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    onClick={showSwalQuitLink}
                  >
                    <i className="ki-duotone ki-cross-square fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-rounded position-relative z-index-1"
          style={{ marginTop: "-100px" }}
        >
          {quizComponent === "Quiz" && <Question />}
          {quizComponent === "Exam" && (
            <Exam finished={finished} setFinished={setFinished} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default QuizPage;
