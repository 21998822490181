import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getExamQuestions } from "features/medecine";
import { useNavigate } from "react-router-dom";


const Exam = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { exams, examQuestions } = useSelector((state) => state.medecine);
    const [questionsReady, setQuestionsReady] = useState(false);

    const selectedExamSession = useSelector((state) => state.home.selectedExamSession);

    const geExamQuestionsAPI = (id) => {
        dispatch(getExamQuestions({ exam_id: id }));
        setQuestionsReady(true);
    }

    if (questionsReady && examQuestions && !examQuestions.message) {
        navigate('/exam');
        return null;
    }
    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="container" id="kt_content_container">
                <div className="row g-5 g-xl-8">
                    {exams && exams
                        .filter(exam => exam.session === selectedExamSession)
                        .map((exam, index) => (
                            <div className="col-xl-4" key={index}>
                                <div className="card card-xl-stretch mb-xl-8">
                                    <div className="card-header border-0 py-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3 mb-1">{exam.module === "" ? exam.speciality : exam.module}</span>
                                            <span className="text-muted fw-semibold fs-7">Examen {exam.session}</span>
                                        </h3>
                                    </div>
                                    <div className="card-body d-flex flex-column">
                                        <div className="pt-5">
                                            <div className="fs-6 d-flex justify-content-between my-4">
                                                <div className="fw-semibold">Année</div>
                                                <div className="d-flex fw-bold">
                                                    {exam.year_display !== "Autre" ? exam.year_display : "Résidanat"}</div>
                                            </div>
                                            <div className="separator separator-dashed"></div>
                                            <div className="fs-6 d-flex justify-content-between my-4">
                                                <div className="fw-semibold">Faculté</div>
                                                <div className="d-flex fw-bold">
                                                    {exam.faculty}</div>
                                            </div>
                                            <div className="separator separator-dashed"></div>
                                            <div className="fs-6 d-flex justify-content-between my-4">
                                                <div className="fw-semibold">Nombre de questions</div>
                                                <div className="d-flex fw-bold">
                                                    {exam.num_questions}</div>
                                            </div>
                                            <div className="separator separator-dashed"></div>
                                            <div className="fs-6 d-flex justify-content-between my-4">
                                                <div className="fw-semibold">Durée</div>
                                                <div className="d-flex fw-bold">
                                                    {exam.duration}</div>
                                            </div>
                                            <div className="separator separator-dashed"></div>

                                            <button
                                                onClick={() => geExamQuestionsAPI(exam.id)}
                                                className="btn btn-primary w-100 py-3 mt-5">Commencer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Exam;
