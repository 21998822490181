import { useCallback } from "react";

const useCloseSidebarOnMobile = () => {
  return useCallback(() => {
    if (window.innerWidth <= 767) {
      const asideElement = document.getElementById("kt_aside");
      asideElement?.classList.remove("drawer-on");
    }
  }, []);
};

export default useCloseSidebarOnMobile;
